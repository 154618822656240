// extracted by mini-css-extract-plugin
export var about1 = "projects-module--about1--GLFG3";
export var about2 = "projects-module--about2--6GL4D";
export var aboutFlex = "projects-module--aboutFlex--qLf+M";
export var aboutTitle = "projects-module--aboutTitle--6J5y1";
export var close = "projects-module--close--4XXDw";
export var contactCont = "projects-module--contactCont--mJRzy";
export var contactDataCont = "projects-module--contactDataCont--Lg0Kb";
export var creditCont = "projects-module--creditCont--0DD59";
export var descriptionCont = "projects-module--descriptionCont--jumLb";
export var followCont = "projects-module--followCont--O2qYM";
export var headerCont = "projects-module--headerCont--DidDQ";
export var hero = "projects-module--hero--ONZ2r";
export var imageCont = "projects-module--imageCont--g0wZh";
export var imageHover = "projects-module--imageHover--wMTRP";
export var img = "projects-module--img--kfT4M";
export var imgPortrait = "projects-module--imgPortrait--o1kfS";
export var line = "projects-module--line--AiWrj";
export var namesContact = "projects-module--namesContact--Tngci";
export var projectCont = "projects-module--projectCont--+BijP";
export var projectCredits = "projects-module--projectCredits--hkzkX";
export var projectDate = "projects-module--projectDate--OhnK4";
export var projectDescription = "projects-module--projectDescription--SxyD2";
export var projectDescriptionBottom = "projects-module--projectDescriptionBottom--vznq5";
export var projectLabelCont = "projects-module--projectLabelCont--c+DVe";
export var projectSubtitle = "projects-module--projectSubtitle--ILpcE";
export var projectTextCont = "projects-module--projectTextCont--WgJCf";
export var projectTitle = "projects-module--projectTitle--rvOHJ";
export var projectType = "projects-module--projectType--vbjVW";
export var projectWrapper = "projects-module--projectWrapper--z-Cv5";
export var relatedCont = "projects-module--relatedCont--PCttA";
export var restCont = "projects-module--restCont--VSDtT";
export var tagCont = "projects-module--tagCont--JkQmx";
export var tags = "projects-module--tags--XiIZr";
export var typeCont = "projects-module--typeCont--cXEAF";